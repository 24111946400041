.div {
    width: 15%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.div-2 {
    align-items: start;
    display: flex;
    margin-bottom: 470px;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 12px 0 -1px 16px;
}

@media (max-width: 991px) {
    .div-2 {
        margin-bottom: 40px;
    }
}

.div-3 {
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
    padding: 2px 0px;
}

.div-6.selected .div-7 {
    color: #00e;
    font-weight: 600;
    /* border-radius: 1px;
    border-right: 4px solid #00e; */
}

.div-6.selected {
    position: relative;
}

.div-6.selected::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 4px;
    /* Border width */
    background-color: #00e;
    border-radius: 12px;
}

.div-66.selected {
    position: relative;
}

.div-66.selected::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 4px;
    /* Border width */
    background-color: #00e;
    border-radius: 12px;
}

.div-66.selected .div-7 {
    color: #00e;
    font-weight: 600;
}

.selected img {
    filter: invert(13%) sepia(100%) saturate(4927%) hue-rotate(244deg) brightness(79%) contrast(152%);
}


.border span {
    color: #00e;
    /* Blue color */
    height: 100%;
    height: 30px;
    /* Set the height to 30 pixels */
    display: inline-block;
    border-radius: 40px;
    border-radius: 8px;
    border: 2px solid #00e;

    /* Center the text vertically within the container */
}

.border {
    align-self: left;
    height: 30px;
    font-weight: 700;
    margin-right: -12px;
}

.div-4 {
    justify-content: space-between;
    display: flex;
    gap: 20px;
}

@media (max-width: 991px) {
    .div-4 {
        margin-right: 3px;
    }
}

.img {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
}

.div-5 {
    color: #2f2f2f;
    align-self: start;
    margin-top: 7px;
    flex-grow: 1;
    white-space: nowrap;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-5 {
        white-space: initial;
    }
}

.div-6 {
    align-items: center;
    display: flex;
    margin-top: 26px;
    gap: 17px;
    cursor: pointer;
    margin-left: 12px;
}

.div-66 {
    align-items: center;
    display: flex;
    margin-top: 12px;
    gap: 17px;
    cursor: pointer;
    margin-left: 12px;
}

.img-2 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
}

.div-7 {
    color: #2e353a;
    align-items: left;
    font: 500 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-8 {
    align-items: center;
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    gap: 20px;
}

.img-3 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
}

.div-9 {
    color: #2e353a;
    font: 500 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-10 {
    align-items: center;
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    gap: 20px;
}

.img-4 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
}

.div-11 {
    color: #2e353a;
    font: 500 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-12 {
    align-items: center;
    display: flex;
    margin-top: 23px;
    justify-content: space-between;
    gap: 20px;
}

.img-5 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
}

.div-13 {
    color: #2e353a;
    font: 500 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-14 {
    align-items: center;
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    gap: 20px;
}

.img-6 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
}

.div-15 {
    color: #2e353a;
    font: 500 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-16 {
    display: flex;
    margin-top: 26px;
    justify-content: space-between;
    gap: 20px;
}

.div-17 {
    justify-content: center;
    color: #2e353a;
    font: 500 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-18 {
    align-items: center;
    display: flex;
    margin-top: 27px;
    justify-content: space-between;
    gap: 20px;
}

.img-7 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
}

.div-19 {
    color: #00e;
    text-align: right;
    font: 700 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.img-8 {
    aspect-ratio: 0.07;
    object-fit: contain;
    object-position: center;
    width: 4px;
    overflow: hidden;
    align-self: end;
    margin-top: 363px;
    max-width: 100%;
}

@media (max-width: 991px) {
    .img-8 {
        margin-top: 40px;
    }
}