.column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 35%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .column {
        width: 100%;
        align-items: center;
    }
}

.backImage {
    cursor: pointer;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
}

@media (max-width: 991px) {
    .backImage {
        margin-top: 10px;
    }
}

.div-login-3 {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    width: 90%;
}

@media (max-width: 991px) {
    .div-login-3 {
        max-width: 100%;
        margin-top: 40px;
    }
}

.div-login-4 {
    color: #2e353a;
    font: 700 32px/60px Amiri, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-login-4 {
        max-width: 100%;
    }
}

.div-login-5 {
    border-radius: 11px;
    background-color: rgba(39, 39, 42, 0.05);
    display: flex;
    margin-top: 48px;
    flex-direction: column;
    padding: 24px 24px 35px;
}

@media (max-width: 991px) {
    .div-login-5 {
        max-width: 100%;
        margin-top: 40px;
        padding: 20px 20px
    }
}

.div-login-6 {
    color: #2e353a;
    align-self: stretch;
    white-space: nowrap;
    font: 700 32px/56px Amiri, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-login-6 {
        max-width: 100%;
        white-space: initial;
    }
}

.div-login-7 {
    color: #2f2f2f;
    letter-spacing: -0.48px;
    align-self: stretch;
    margin-top: 12px;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-login-7 {
        max-width: 100%;
    }
}

.div-login-8 {
    color: #7e84a3;
    background-color: rgba(39, 39, 42, 0.05);
    padding: 10px;
    border-radius: 8px;
    border: 0.5px solid #7e84a3;
    align-self: stretch;
    padding: 12px 12px;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
    width: 95%;
}

@media (max-width: 991px) {
    .div-login-8 {
        max-width: 100%;
        white-space: initial;
    }
}

.div-login-9 {
    color: #2f2f2f;
    letter-spacing: -0.48px;
    align-self: stretch;
    margin-top: 24px;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-login-9 {
        max-width: 100%;
    }
}

.div-login-10 {
    justify-content: center;
    color: #7e84a3;
    text-align: center;
    letter-spacing: -0.48px;
    border-radius: 8px;
    border: 0.5px solid #7e84a3;
    align-self: stretch;
    align-items: start;
    padding: 18px;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-login-10 {
        max-width: 100%;
    }
}

.disabled1 {
    color: #fff;
    text-align: center;

    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 4px;
    background-color: rgb(131, 131, 139);
    align-self: stretch;
    margin-top: 25px;
    align-items: center;
    padding: 13px 20px;
    font: 700 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-login-11 {
    color: #fff;
    text-align: center;
    cursor: pointer;
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #00e;
    align-self: stretch;
    margin-top: 25px;
    align-items: center;
    padding: 13px 20px;
    font: 700 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-login-11 {
        white-space: initial;
        max-width: 100%;
    }
}

.div-login-button {
    color: #fff;
    text-align: center;
    cursor: pointer;
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #00e;
    align-self: stretch;
    margin-top: 25px;
    align-items: center;
    padding: 13px 20px;
    font: 700 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
    width: 100%;
}

@media (max-width: 991px) {
    .div-login-button {
        white-space: initial;
        max-width: 100%;
    }
}


.ForgetPassword {
    cursor: pointer;
    color: #00e;
    letter-spacing: -0.48px;
    align-self: stretch;
    margin-top: 16px;
    white-space: nowrap;
    font: 500 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.error {
    border-color: red;
}

.div-login-12 {
    color: #00e;
    cursor: pointer;
    letter-spacing: -0.48px;
    align-self: stretch;
    margin-top: 16px;
    font: 500 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .div-login-12 {
        max-width: 100%;
    }
}

.img {
    aspect-ratio: 2.62;
    object-fit: contain;
    object-position: center;
    width: 89px;
    mix-blend-mode: darken;
    overflow: hidden;
    align-self: center;
    margin-top: 60px;
    max-width: 100%;
}

@media (max-width: 991px) {
    .img {
        margin-top: 40px;
    }
}

.column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 65%;
    height: 100%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    .column-2 {
        width: 100%;
    }
}

.column-4 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 49%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    .column-4 {
        width: 100%;
    }
}

.column-5 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 51%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .column-5 {
        width: 100%;
    }
}


.column-6 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 49%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    .column-6 {
        width: 100%;
    }
}

.column-7 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 51%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .column-7 {
        width: 100%;
    }
}

.column-8 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 49%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    .column-8 {
        width: 100%;
    }
}

.forgetpass {
    color: #2e353a;
    text-align: left;
    align-self: stretch;
    margin-top: 12px;
    white-space: nowrap;
    font: 700 32px/56px Amiri, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .forgetpass {
        max-width: 100%;
        white-space: initial;
    }
}

.resetpassword {
    cursor: pointer;
    color: #00e;
    text-align: center;
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 4px;
    border: 1px solid #00e;
    background-color: #e8e8e8;
    align-self: stretch;
    align-items: center;
    padding: 15px 20px;
    font: 700 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .resetpassword {
        white-space: initial;
        max-width: 100%;
    }
}

.resendmail {
    cursor: pointer;
    color: #00e;
    letter-spacing: -0.48px;
    align-self: stretch;
    margin-top: 16px;
    margin-bottom: 16px;
    font: 500 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .resendmail {
        max-width: 100%;
    }
}