.divcp {
    border-radius: 16px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    padding: 26px 0;
}

@media (max-width: 991px) {
    .divcp {
        max-width: 100%;
    }
}


.modal-contentcp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #fff;
    border-radius: 12px;
    max-width: 340px;
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;
}

.modal-contentcp div {
    padding: 8px 6px;
    cursor: pointer;
}

.divcp-2 {
    color: #2e353a;
    font-feature-settings: "clig" off, "liga" off;
    margin-left: 21px;
    white-space: nowrap;
    font: 700 24px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divcp-2 {
        margin-left: 10px;
        white-space: initial;
    }
}

.divcp-3 {
    background-color: #e8e8e8;
    align-self: stretch;
    display: flex;
    margin-top: 27px;
    /* width: 100%; */
    flex-direction: column;
}

@media (max-width: 991px) {
    .divcp-3 {
        max-width: 100%;
        padding: 0 20px;
    }
}

.divcp-4 {
    color: #2f2f2f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divcp-4 {
        max-width: 100%;
    }
}

.divcp-5 {
    padding-left: 8px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    margin-top: 6px;
    height: 40px;
    flex-direction: column;
}

@media (max-width: 991px) {
    .divcp-5 {
        max-width: 100%;
    }
}

.divcp-6 {
    color: #2f2f2f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    margin-top: 16px;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divcp-6 {
        max-width: 100%;
    }
}

.divcp-7 {
    color: #7a797f;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    white-space: nowrap;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    margin-top: 6px;
    justify-content: center;
    align-items: start;
    padding: 18px 60px 18px 14px;
    font: 400 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divcp-7 {
        white-space: initial;
        max-width: 100%;
        padding-right: 20px;
    }
}

.divcp-8 {
    color: #2f2f2f;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    margin-top: 16px;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divcp-8 {
        max-width: 100%;
    }
}

.divcp-9 {
    color: #7a797f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    white-space: nowrap;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    margin-top: 6px;
    justify-content: center;
    align-items: start;
    padding: 18px 60px 18px 14px;
    font: 400 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divcp-9 {
        white-space: initial;
        max-width: 100%;
        padding-right: 20px;
    }
}

.divcp-10 {
    color: #f7fbfe;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    border-radius: 8px;
    background-color: #00e;
    align-self: center;
    margin-top: 24px;
    padding: 13px 54px;
    font: 600 16px/22px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divcp-10 {
        white-space: initial;
        padding: 0 20px;
    }
}