.div-login-13 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

@media (max-width: 991px) {
    .div-login-13 {
        max-width: 100%;
        margin-top: 40px;
    }
}

.div-login-15 {
    display: flex;
    margin: -1px;
}

@media (max-width: 991px) {
    .div-login-15 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

.column-3 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 51%;
    margin-left: -1px;
}

@media (max-width: 991px) {
    .column-3 {
        width: 100%;
    }
}


.img-login-3 {
    object-position: center;
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
}

@media (max-width: 991px) {
    .img-login-3 {
        max-width: 100%;
    }
}