.main-container-header {
    display: flex;
    padding: 0px 16px;
    justify-content: space-between;
    gap: 20px;
    background-color: white;
    border-bottom: 1px solid black;
    /* Updated border property */
}

.options-not {
    max-height: 250px;
    overflow-y: auto;
    position: absolute;
    flex: 1;
    margin-top: 15px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    min-width: 250px;
    border-radius: 0px 0px 8px 8px;
}

.img-not {
    align-items: start;
    margin-bottom: auto;
    padding-right: 8px;
    padding-left: 4px;
}

.con-not {
    padding: 8px;
    /* height: 100px; */
    display: flex;
    justify-content: space-between;
    border-bottom: #C8C8C8 1px solid;
}


.notification-3 {
    color: var(--grey-primary, #000);
    letter-spacing: 0.15px;
    align-self: stretch;
    flex-grow: 1;
    flex-basis: auto;
    font: 400 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .main-container {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.main-image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 60px;
    mix-blend-mode: darken;
    overflow: hidden;
    max-width: 100%;
}

.image-wrapper {
    display: flex;
    gap: 16px;
}

/* .a {
    cursor: pointer;

} */


@media (max-width: 991px) {
    .image-wrapper {
        justify-content: center;
    }
}

.secondary-image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: center;
    /* max-width: 100%;
    margin: auto 0; */
}

.user-name {
    color: #fff;
    letter-spacing: 0.15px;
    white-space: nowrap;
    border-radius: 50%;
    padding: 11px 0;
    font: 400 16px/24px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.user-name1 {
    margin: 0;
    color: white;
    background-color: blue;
    padding: 3px 7px;
    border-radius: 50%;
    display: inline-block;
    font: 400 12px/24px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    align-self: center;
    margin: 8px;

}


@media (max-width: 991px) {
    .user-name {
        white-space: initial;
    }
}

.user-details {
    align-self: center;
    display: flex;
    gap: 2px;
    margin: auto 0;
}

.user-name {
    color: var(--grey-primary, #565656);
    letter-spacing: 0.15px;
    font: 400 16px/24px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    align-self: center;
}

.user-image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
}