.dropdown-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.arrow {
    width: 0;
    height: 0;
    border-style: solid;
    /* border-width: 5px 5px 0; */
    border-color: #333 transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.options {
    position: absolute;
    flex: 1;
    margin-top: 10px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    min-width: 200px;
    border-radius: 0px 0px 8px 8px;
}

.logout {
    color: #00e;
    letter-spacing: 0.15px;
    flex-grow: 1;
    white-space: nowrap;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.changePassword {
    color: #2f2f2f;
    letter-spacing: 0.15px;
    flex-grow: 1;
    white-space: nowrap;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.con {
    height: 40px;
    display: flex;
    justify-content: space-between;
    border-bottom: #C8C8C8 1px solid;
}

.con1 {
    display: flex;
    justify-content: space-between;
}

.divcp-error {
    margin-top: 8px;
    color: red;
}

.divcp-error2 {
    margin-top: 8px;
    color: green;
}

.imglogout {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
}

.options div {
    padding: 8px 6px;
    cursor: pointer;
}

.options div:hover {
    /* background-color: #c8c8c8; */
}

.user-image2 {
    margin-top: 10px;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
}