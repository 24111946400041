.more-details-container {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.header-more {
  color: #00e;
  text-align: right;
  flex-grow: 1;
  white-space: nowrap;
  font: 450 14px Cairo, sans-serif;
}

@media (max-width: 991px) {
  .header-more {
    white-space: initial;
  }
}

.img-more {
  aspect-ratio: 1.19;
  object-fit: contain;
  object-position: center;
  width: 19px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.loc {
  padding: 12px;
  background-color: white;
}

.inquires {
  justify-content: space-between;
  display: flex;
}

.all-inquires {
  color: #2e353a;
  letter-spacing: 0.2px;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 700 20px Cairo, sans-serif;
}

.custom-table {
  width: 100%;
  margin-top: 20px;
}

.name-wrapper {
  align-self: center;
}

.assign-wrapper {
  display: flex;
  justify-content: space-between;
}


table {
  border-spacing: 0;
}



thead {
  border-radius: 30px;
  background-color: #f2f2f2;
  color: black;
  border: 0.5px solid #00e;
  border: 1px solid black;
  border-collapse: collapse
}


table th {
  text-align: left;
  padding: 8px;
  background-color: #f2f2f2;

}

table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

td {
  padding: 8px;
  text-align: left;
  border-bottom: 2px solid #f2f2f2;
  color: #474747;
  letter-spacing: 0.16px;
  font: 500 12px Cairo, sans-serif;
}



.main-container1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header1 {
  border-radius: 50px;
  border: 1px solid #474747;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0;
}

.header-table {
  display: flex;
  width: 94px;
  border-radius: 30px;
  border: 1px solid #474747;
  /* padding: 0px 10px; */
  gap: 7px;
  height: 38px;
}

.img-filter {
  height: 18px;
  width: 20px;
  align-self: center;
  padding-left: 16px
}

.filter {
  color: #565656;
  align-self: center;
  font: 400 14px Poppins, sans-serif;
}

@media (max-width: 991px) {
  .filter {
    white-space: initial;
  }
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
  font-size: 15px;
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}

.pagination .page-num:hover {
  background-color: #d3d3d3;
}

.active {
  padding: 8px 0px;
  color: white;
  border-radius: 3px;
  background-color: #00e;
}

.disabled {
  background-color: none;
  color: #aaa;
  pointer-events: none;
  cursor: not-allowed;
  margin-top: 0px;
}