.modal-content2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #fff;
    border-radius: 12px;
    max-width: 500px;
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
}

button {
    border-style: solid;
    border: none
}

.email-error {
    color: red;
    font-weight: 500px;
    font-size: 18px;
    margin-top: 10px;
}

.close {
    cursor: pointer;
    padding-right: 15px;
}

.divup {
    align-items: start;
    display: flex;
    padding-top: 14px;
    flex-direction: column;
}

.divup-2 {
    color: #00e;
    font-feature-settings: "clig" off, "liga" off;
    margin-left: 25px;
    white-space: nowrap;
    font: 700 16px/175% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divup-2 {
        margin-left: 10px;
        white-space: initial;
    }
}

.divup-3 {
    background-color: #e8e8e8;
    align-self: stretch;
    display: flex;
    margin-top: 28px;
    flex-direction: column;
    padding: 15px 24px;
    border-radius: 0 0 12px 12px;
}

@media (max-width: 991px) {
    .divup-3 {
        max-width: 100%;
        padding: 0 20px;
    }
}

.divup-4 {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

@media (max-width: 991px) {
    .divup-4 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.divup-5 {
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.divup-6 {
    color: #2f2f2f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divup-7 {
    justify-content: flex-end;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    margin-top: 16px;
    padding-left: 8px;
    height: 40px;
    flex-direction: column;
}

.divup-8 {
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.divup-9 {
    color: #2f2f2f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divup-10 {
    justify-content: flex-end;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    margin-top: 16px;
    height: 40px;
    flex-direction: column;
}

.divup-11 {
    color: #2f2f2f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    margin-top: 16px;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divup-11 {
        max-width: 100%;
    }
}

.divup-12 {
    color: #7a797f;
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    white-space: nowrap;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    margin-top: 6px;
    justify-content: center;
    align-items: start;
    padding: 13px 60px 13px 14px;
    font: 400 16px/175% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divup-12 {
        white-space: initial;
        max-width: 100%;
        padding-right: 20px;
    }
}

.divup-13 {
    color: #2f2f2f;
    text-align: left;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    margin-top: 16px;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divup-13 {
        max-width: 100%;
    }
}

.divup-14 {
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    margin-top: 6px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 8px 14px;
}

@media (max-width: 991px) {
    .divup-14 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.divup-15 {
    color: #7a797f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    margin: auto 0;
    font: 400 16px/175% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.img {
    aspect-ratio: 0.83;
    object-fit: contain;
    object-position: center;
    width: 20px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.divup-16 {
    color: #f7fbfe;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #00e;
    align-self: center;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 200px;
    max-width: 100%;
    padding: 15px 60px;
    font: 600 16px/135% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divup-16 {
        white-space: initial;
        padding: 0 20px;
    }
}

.email-confirmation {
    border-radius: 16px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    .email-confirmation {
        max-width: 100%;
    }
}

.user-info {
    padding-left: 16px;
    align-self: center;
    justify-content: space-between;
    gap: 20px;
}

.close-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .user-info {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.user-code {
    color: var(--grey-primary, #565656);
    font-feature-settings: "clig" off, "liga" off;
    font: 400 16px/28px Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

.user-phone {
    color: #2e353a;
    font-feature-settings: "clig" off, "liga" off;
    margin-top: 8px;
    white-space: nowrap;
    font: 700 24px/117% Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .user-phone {
        white-space: initial;
    }
}

.logo {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: start;
    max-width: 100%;
    padding-right: 16px;
    cursor: pointer;
}

.divider {
    background-color: #e8e8e8;
    align-self: stretch;
    display: flex;
    min-height: 2px;
    margin-top: 26px;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    .divider {
        max-width: 100%;
    }
}

.imagepop {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 221px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    align-self: center;
    margin-top: 22px;
    max-width: 100%;
}

.confirmation-msg {
    color: #2e353a;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    max-width: 300px;
    align-self: center;
    font: 700 24px/28px Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

.confirmation-msg h1 {
    font-family: Cairo, sans-serif;
    font-weight: 700;
    font-size: 30px;
}

.confirmation-msg h2 {
    font-family: Cairo, sans-serif;
    font-size: 26px;
    font-weight: 200;
}

.confirm-btn {
    color: #f7fbfe;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #00e;
    align-self: center;
    cursor: pointer;
    margin: 20px;
    width: 100%;
    max-width: 340px;
    padding: 14px 60px;
    font: 600 16px/135% Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .confirm-btn {
        white-space: initial;
        margin-top: 40px;
        padding: 0 20px;
    }
}