.percentage {
    margin-top: 100px;
    flex-direction: column;

}

input {
    border: 0;
    outline: 0;
}

::file-selector-button {
    display: none;
}

.divba {
    width: 95%;
    display: flex;
    flex-direction: column;
}

.divba-202 {
    margin-bottom: 8px;
    color: #0000EE;
    letter-spacing: 0.24px;
    width: 100%;
    font: 700 24px Cairo, sans-serif;
}

.divba-2 {
    margin-bottom: 8px;
    color: #36393e;
    letter-spacing: 0.24px;
    width: 100%;
    font: 700 24px Cairo, sans-serif;
}

@media (max-width: 991px) {
    .divba-2 {
        max-width: 100%;
    }
}

.divba-3 {
    disply: flex;
    flex-direction: column;
    fill: #fff;
    overflow: hidden;
    position: relative;
    display: flex;
    width: 100%;
    padding: 16px;
}

.divba-303 {
    disply: flex;
    flex-direction: column;
    fill: #fff;
    overflow: hidden;
    position: relative;
    display: flex;
    width: 100%;
    padding: 16px;
}

@media (max-width: 991px) {
    .divba-3 {
        max-width: 100%;
    }
}

.imgba {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.divba-4 {
    position: relative;
}

@media (max-width: 991px) {
    .divba-4 {
        max-width: 100%;
    }
}

.divba-5 {
    gap: 20px;
    display: flex;
}

.divba-505 {
    display: flex;
}

@media (max-width: 991px) {
    .divba-5 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

.container-for {
    display: flex;
    gap: 40px;
}

.container-for1 {
    width: 100%;
}

.columnba {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 49%;
    margin-left: 0px;
}

.columnba-303 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 100%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .columnba {
        width: 100%;
    }
}

.divba-6 {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    .divba-6 {
        max-width: 100%;
        margin-top: 40px;
    }
}

.divba-7 {}

@media (max-width: 991px) {
    .divba-7 {
        max-width: 100%;
    }
}

.divba-8 {
    gap: 20px;
    display: flex;
}

@media (max-width: 991px) {
    .divba-8 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

.columnba-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 58%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .columnba-2 {
        width: 100%;
    }
}

.divba-9 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: end;
}

@media (max-width: 991px) {
    .divba-9 {
        margin-top: 16px;
    }
}

.divba-10 {
    color: #7e84a3;
    text-align: center;
    letter-spacing: -0.48px;
    align-self: stretch;
    font: 600 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-11 {
    color: #2f2f2f;
    text-align: center;
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 8px;
    border: 0.5px solid #36393e;
    align-self: stretch;
    margin-top: 8px;
    justify-content: center;
    align-items: start;
    min-width: 205px;
    padding: 13px 60px 13px 15px;
    font: 400 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-11 {
        white-space: initial;
        padding-right: 20px;
    }
}

.divba-12 {
    color: #fff;
    cursor: pointer;
    text-align: center;
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #00e;
    margin-top: 95px;
    justify-content: center;
    padding: 9px 29px;
    font: 600 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-12 {
        white-space: initial;
        margin-top: 40px;
        padding: 0 20px;
    }
}

.columnba-3 {
    border-radius: 8px;
    border: 0.5px solid #2f2f2f;
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 42%;
    max-width: 260px;
    max-height: 236px;
    /* min-width: 190px; */
    border: 0.5px solid #2f2f2f;
}

.columnba-304 {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 42%;
    max-width: 260px;
    max-height: 236px;
}

@media (max-width: 991px) {
    .columnba-3 {
        width: 100%;
    }
}

.imgba-2 {
    aspect-ratio: 1.44;
    object-fit: contain;
    object-position: center;
    width: 100%;
    max-width: 1010px;
    overflow: hidden;
    flex-grow: 1;
}

@media (max-width: 991px) {
    .imgba-2 {
        margin-top: 16px;
    }
}

.columnba-4 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 50%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    .columnba-4 {
        width: 100%;
    }
}

.divba-13 {
    justify-content: flex-end;
    position: relative;
    flex-grow: 1;
}

@media (max-width: 991px) {
    .divba-13 {
        max-width: 100%;
        margin-top: 40px;
    }
}

.divba-14 {
    gap: 20px;
    display: flex;
}

@media (max-width: 991px) {
    .divba-14 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

.columnba-5 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 58%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .columnba-5 {
        width: 100%;
    }
}

.divba-15 {
    align-items: end;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

@media (max-width: 991px) {
    .divba-15 {
        margin-top: 10px;
    }
}

.divba-16 {
    color: #36393e;
    text-align: left;
    letter-spacing: -0.48px;
    align-self: stretch;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-17 {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.divba-18 {
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    padding-top: 10px;
    flex-direction: column;
}

.divba-19 {
    color: #00e;
    text-align: left;
    letter-spacing: -0.48px;
    font: 400 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-20 {
    border-radius: 8px;
    border: 0.5px solid #36393e;
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 8px 17px;
}

.divba-21 {
    color: #2f2f2f;
    text-align: center;
    letter-spacing: -0.48px;
    margin: auto 0;
    font: 400 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.imgba-3 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.divba-22 {
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    padding-top: 10px;
    flex-direction: column;
}

.divba-23 {
    color: #00e;
    text-align: left;
    letter-spacing: -0.48px;
    font: 400 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-24 {
    border-radius: 8px;
    border: 0.5px solid #36393e;
    display: flex;
    margin-top: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 5px 16px;
}

.divba-25 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 3px 1px;
}

.divba-26 {
    color: #2f2f2f;
    text-align: center;
    letter-spacing: -0.48px;
    margin: auto 0;
    font: 400 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.imgba-4 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.divba-27 {
    color: #fff;
    text-align: center;
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #00e;
    margin-top: 70px;
    justify-content: center;
    padding: 9px 29px;
    cursor: pointer;
    font: 600 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-27 {
        white-space: initial;
        margin-top: 40px;
        padding: 0 20px;
    }
}

.columnba-6 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 42%;
    margin-left: 20px;
    max-width: 260px;
    max-height: 236px;
}

@media (max-width: 991px) {
    .columnba-6 {
        width: 100%;
    }
}

.divba-28 {
    border-radius: 8px;
    border: 0.5px solid #2f2f2f;
    background-color: #fff;
    position: relative;
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 260px;
    max-height: 236px;
}

@media (max-width: 991px) {
    .divba-28 {
        margin-top: 10px;
        padding: 0 20px;
    }
}

.divba-29 {
    display: flex;
    gap: 0px;
    margin: 24px 0 23px;
    padding: 0 2px;
}

.divba-30 {
    border-radius: 5px;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

@media (max-width: 991px) {
    .divba-30 {
        padding-right: 20px;
    }
}

.divba-31 {
    color: var(--www-aos-fit-japanese-laurel, #ff6e00);
    text-align: center;
    white-space: nowrap;
    font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-31 {
        white-space: initial;
    }
}

.divba-32 {
    color: var(--www-aos-fit-black-40, rgba(0, 0, 0, 0.4));
    text-align: center;
    text-transform: uppercase;
    margin-top: 5px;
    white-space: nowrap;
    font: 400 10px/142.9% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-32 {
        white-space: initial;
    }
}

.divba-33 {
    border-radius: 5px;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
    padding: 0 25px;
}

@media (max-width: 991px) {
    .divba-33 {
        padding: 0 20px;
    }
}

.divba-34 {
    color: var(--www-aos-fit-japanese-laurel, #ff6e00);
    text-align: center;
    white-space: nowrap;
    font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-34 {
        white-space: initial;
    }
}

.divba-35 {
    color: var(--www-aos-fit-black-40, rgba(0, 0, 0, 0.4));
    text-align: center;
    text-transform: uppercase;
    margin-top: 5px;
    white-space: nowrap;
    font: 400 10px/145% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-35 {
        white-space: initial;
    }
}

.divba-36 {
    border-radius: 5px;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
    padding: 0 13px 0 0px;
}

@media (max-width: 991px) {
    .divba-36 {
        padding-left: 20px;
    }
}

.divba-37 {
    color: var(--www-aos-fit-japanese-laurel, #ff6e00);
    text-align: center;
    white-space: nowrap;
    font: 700 22px/39px Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-37 {
        white-space: initial;
    }
}

.divba-38 {
    color: var(--www-aos-fit-black-40, rgba(0, 0, 0, 0.4));
    text-align: center;
    text-transform: uppercase;
    margin-top: 5px;
    white-space: nowrap;
    font: 400 10px/142.9% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-38 {
        white-space: initial;
    }
}

.divba-39 {
    position: relative;
    margin-top: 16px;
}

@media (max-width: 991px) {
    .divba-39 {
        max-width: 100%;
    }
}

.divba-40 {
    display: flex;
}

@media (max-width: 991px) {
    .divba-40 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

.columnba-7 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 28%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .columnba-7 {
        width: 100%;
    }
}

.divba-41 {
    align-items: flex-end;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 2px;
}

@media (max-width: 991px) {
    .divba-41 {
        margin-top: 16px;
    }
}

.divba-42 {
    color: #36393e;
    text-align: left;
    letter-spacing: -0.48px;
    align-self: stretch;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-43 {
    color: #00e;
    text-align: left;
    letter-spacing: -0.48px;
    align-self: stretch;
    margin-top: 9px;
    font: 400 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-44 {
    color: #2f2f2f;
    text-align: center;
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 8px;
    border: 0.5px solid #36393e;
    align-self: stretch;
    margin-top: 8px;
    justify-content: center;
    align-items: start;
    padding: 15px 60px 15px 18px;
    font: 400 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-44 {
        white-space: initial;
        padding-right: 20px;
    }
}

.divba-45 {
    color: #fff;
    cursor: pointer;
    text-align: center;
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #00e;
    align-self: end;
    margin-top: 70px;
    justify-content: center;
    padding: 9px 29px;
    font: 600 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-45 {
        white-space: initial;
        margin-top: 40px;
        padding: 0 20px;
    }
}

.columnba-8 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 23%;
    margin-left: 20px;
    max-width: 260px;
    max-height: 236px;
}

@media (max-width: 991px) {
    .columnba-8 {
        width: 100%;
    }
}

.divba-46 {
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    max-width: 260px;
    max-height: 236px;
    gap: 10px;
    justify-content: center;
}

@media (max-width: 991px) {
    .divba-46 {
        margin-top: 16px;
    }
}

.divba-47 {
    justify-content: center;
    color: #000;
    text-align: center;
    /* flex-grow: 1; */
    white-space: nowrap;
    margin: auto 0;
    font: 700 12px/145% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-47 {
        white-space: initial;
    }
}

.divba-48 {
    justify-content: center;
    color: #00e;
    text-align: center;
    margin: auto 0;
    font: 700 12px/136% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-49 {
    justify-content: center;
    color: #000;
    text-align: center;
    margin: auto 0;
    font: 700 12px/138% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-50 {
    justify-content: center;
    color: #00e;
    text-align: center;
    white-space: nowrap;
    margin: auto 0;
    font: 700 12px/130% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-50 {
        white-space: initial;
    }
}

.columnba-9 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 49%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    .columnba-9 {
        width: 100%;
    }
}

.divba-51 {
    position: relative;
    flex-grow: 1;
}

@media (max-width: 991px) {
    .divba-51 {
        max-width: 100%;
        margin-top: 16px;
    }
}

.divba-52 {
    gap: 20px;
    display: flex;
}

@media (max-width: 991px) {
    .divba-52 {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
}

.columnba-10 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 58%;
    margin-left: 0px;
}

@media (max-width: 991px) {
    .columnba-10 {
        width: 100%;
    }
}

.divba-53 {
    align-items: flex-end;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 2px;
}

@media (max-width: 991px) {
    .divba-53 {
        margin-top: 16px;
    }
}

.divba-54 {
    color: #36393e;
    text-align: left;
    letter-spacing: -0.48px;
    align-self: stretch;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-55 {
    color: #00e;
    text-align: left;
    letter-spacing: -0.48px;
    align-self: stretch;
    margin-top: 9px;
    font: 400 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-106 {
    width: 150px;
    border-radius: 8px;
    border: 0px solid #36393e;
    align-self: stretch;
    display: flex;
    margin-top: 8px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 8px 17px;
}

.divba-56 {

    border-radius: 8px;
    border: 0.5px solid #36393e;
    align-self: stretch;
    display: flex;
    margin-top: 8px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 8px 17px;
}

.divba-57 {
    color: #2f2f2f;
    text-align: center;
    letter-spacing: -0.48px;
    margin: auto 0;
    font: 400 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.imgba-5 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.divba-58 {
    color: #fff;
    text-align: center;
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #00e;
    align-self: end;
    margin-top: 70px;
    justify-content: center;
    cursor: pointer;
    padding: 9px 29px;
    font: 600 16px/187.5% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divba-p {
    margin-top: 10px;
    color: green;
}

.divba-e {
    margin-top: 10px;
    color: red;
}

@media (max-width: 991px) {
    .divba-58 {
        white-space: initial;
        margin-top: 40px;
        padding: 0 20px;
    }
}

.columnba-11 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 42%;
    margin-left: 20px;
    max-width: 260px;
    max-height: 236px;
}

@media (max-width: 991px) {
    .columnba-11 {
        width: 100%;
    }
}

.divba-59 {
    justify-content: center;
    color: #00e;
    text-align: center;
    white-space: nowrap;
    border-radius: 8px;
    border: 0.5px solid #2f2f2f;
    background-color: #fff;
    position: relative;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    font: 700 16px/136% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divba-59 {
        white-space: initial;
        margin-top: 16px;
        padding: 40px 20px;
    }
}