.dropdown-container1 {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.arrow1 {
    width: 0;
    height: 0;
    border-style: solid;
    /* border-width: 5px 5px 0; */
    border-color: #333 transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.options2 {
    position: absolute;
    flex: 1;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    min-width: 200px;
    border-radius: 8px 8px 8px 8px;
}

.options2 ::hover {
    background-color: #333;
}

.logout1 {
    color: #00e;
    letter-spacing: 0.15px;
    flex-grow: 1;
    white-space: nowrap;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.changePassword1 {
    color: #2f2f2f;
    letter-spacing: 0.15px;
    flex-grow: 1;
    white-space: nowrap;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.con1 {
    height: 40px;
    display: flex;
    justify-content: space-between;
    border-bottom: #C8C8C8 1px solid;
}

.con11 {
    display: flex;
    justify-content: space-between;
}

.imglogout1 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
}

/* .options2 div {
    padding: 3px 7px;
} */

/* .options1 div:hover {
    background-color: #c8c8c8;
} */

.user-image21 {
    margin-top: 10px;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
}

.userwrapper1 {
    cursor: pointer;
    min-width: 200px;
    border-bottom: #e8e8e8 1px solid;
    display: flex;

}

.userwrapper1:hover {
    background-color: #c8c8c8;
}

.div-option2 {
    color: #2f2f2f;
    letter-spacing: 0.15px;
    width: 100%;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
    border-bottom: 2px solid #e8e8e8;
}

.div-option2:hover {
    background-color: #c8c8c8;
}


.div-option4 {
    color: #ff6e00;
    letter-spacing: 0.15px;
    margin-top: 7px;
    width: 100%;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
    margin-bottom: 4px;
    border-bottom: 2px solid #e8e8e8;
}

.div-option4:hover {
    background-color: #c8c8c8;
}

.div-option6 {
    color: #1ac6b1;
    letter-spacing: 0.15px;
    margin-top: 9px;
    width: 100%;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
    margin-bottom: 4px;
    border-bottom: 2px solid #e8e8e8;
}

.div-option6:hover {
    background-color: #c8c8c8;
}

.div-option8 {
    color: #0068ff;
    letter-spacing: 0.15px;
    margin-top: 7px;
    width: 100%;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
    margin-bottom: 4px;
    border-bottom: 2px solid #e8e8e8;
}

.div-option8:hover {
    background-color: #c8c8c8;
}


.div-option10 {
    color: #ffc75a;
    letter-spacing: 0.15px;
    margin-top: 8px;
    width: 100%;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
    margin-bottom: 4px;
    border-bottom: 2px solid #e8e8e8;
}

.div-option10:hover {
    background-color: #c8c8c8;
}

.div-option12 {
    color: #f00;
    letter-spacing: 0.15px;
    margin-top: 8px;
    width: 100%;
    font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.div-option12:hover {
    background-color: #c8c8c8;
}