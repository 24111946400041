.image-wrapper1 {
    justify-content: space-between;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.image {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
}

.title {
    color: #fff;
    text-align: center;
    letter-spacing: -0.48px;
    align-self: stretch;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

.input-container1 {
    border-radius: 4px;
    background-color: #00e;
    display: flex;
    justify-content: center;
    padding: 5px 20px;
}

td {
    padding: 16px;
}