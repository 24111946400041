.contactp {
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 16px;
}

@media (max-width: 991px) {
    .contactp {
        max-width: 100%;
    }
}

.contactp-2 {
    justify-content: center;
    background-color: #fff;
    align-self: stretch;
    display: flex;
    flex-direction: column;
}

@media (max-width: 991px) {
    .contactp-2 {
        max-width: 100%;
    }
}

.contactp-3 {
    justify-content: space-between;
    align-items: start;
    display: flex;
    gap: 20px;
    padding: 0 1px;
}

@media (max-width: 991px) {
    .contactp-3 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.contactp-4 {
    align-self: stretch;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.contactp-5 {
    color: var(--grey-primary, #565656);
    font-feature-settings: "clig" off, "liga" off;
    white-space: nowrap;
    font: 400 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .contactp-5 {
        white-space: initial;
    }
}

.contactp-6 {
    color: #2e353a;
    font-feature-settings: "clig" off, "liga" off;
    margin-top: 8px;
    white-space: nowrap;
    font: 700 24px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .contactp-6 {
        white-space: initial;
    }
}

.contactp-7 {
    align-self: stretch;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.contactp-8 {
    color: var(--grey-primary, #565656);
    font-feature-settings: "clig" off, "liga" off;
    white-space: nowrap;
    font: 400 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .contactp-8 {
        white-space: initial;
    }
}

.contactp-9 {
    color: #2e353a;
    font-feature-settings: "clig" off, "liga" off;
    margin-top: 8px;
    white-space: nowrap;
    font: 700 24px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .contactp-9 {
        white-space: initial;
    }
}

.img-pop {
    cursor: pointer;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    max-width: 100%;
}

.contactp-10 {
    background-color: #fff;
    align-self: stretch;
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    .contactp-10 {
        max-width: 100%;
    }
}

.contactp-11 {
    background-color: #e8e8e8;
    display: flex;
    height: 2px;
    flex-direction: column;
}

@media (max-width: 991px) {
    .contactp-11 {
        max-width: 100%;
    }
}

.contactp-12 {
    background-color: #fff;
    align-self: stretch;
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 991px) {
    .contactp-12 {
        max-width: 100%;
    }
}

.contactp-13 {
    border-radius: 8px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 13px 17px;
}

@media (max-width: 991px) {
    .contactp-13 {
        max-width: 100%;
    }
}

.contactp-14 {
    justify-content: space-between;
    display: flex;
    gap: 16px;
}

@media (max-width: 991px) {
    .contactp-14 {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.contactp-15 {
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
    justify-content: center;
}

.contactp-16 {
    border-radius: 50px;
    border: 1px solid #474747;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 8px 0;
}

.contactp-17 {
    justify-content: space-between;
    display: flex;
    gap: 4px;
    padding: 0 48px;
}

@media (max-width: 991px) {
    .contactp-17 {
        padding: 0 20px;
    }
}

.contactp-18 {
    color: #565656;
    align-self: center;
    flex-grow: 1;
    white-space: nowrap;
    margin: auto 0;
    font: 400 14px Poppins, sans-serif;
}

@media (max-width: 991px) {
    .contactp-18 {
        white-space: initial;
    }
}

.contactp-18-custom {
    color: #565656;
    align-self: center;
    flex-grow: 1;
    white-space: nowrap;
    margin: auto 0;
    font: 400 14px Poppins, sans-serif;
    width: 45%;
}


.contactp-19 {
    display: flex;
    flex-basis: 0%;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
    max-width: 300px;
}

.contactp-19-custom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%
}

.contactp-20 {
    border-radius: 50px;
    border: 1px solid #474747;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 8px 0;

}

.contactp-21 {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 4px;
    padding: 0 36px;
    align-self: center;
}

@media (max-width: 991px) {
    .contactp-21 {
        padding: 0 20px;
    }
}

.img-pop-2 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.contactp-22 {
    color: #565656;
    margin: auto 0;
    font: 400 14px Poppins, sans-serif;
}

.img-pop-3 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.contactp-23 {
    cursor: pointer;
    min-width: 170px;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
    justify-content: center;
}

.contactp-24 {
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid #474747;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 8px 0;
}

.contactp-25 {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 4px;
    padding: 0 29px;
}

@media (max-width: 991px) {
    .contactp-25 {
        padding: 0 20px;
    }
}

.img-pop-4 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.contactp-26 {
    color: #565656;
    margin: auto 0;
    font: 400 14px Poppins, sans-serif;
}

.img-pop-5 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.contactp-27 {
    align-self: stretch;
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    gap: 16px;
}

@media (max-width: 991px) {
    .contactp-27 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.contactp-28 {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.contactp-29 {
    color: #2f2f2f;
    /* text-align: center; */
    letter-spacing: -0.48px;
    white-space: nowrap;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .contactp-29 {
        white-space: initial;
    }
}

.contactp-30 {
    color: #7e84a3;
    /* text-align: center; */
    letter-spacing: -0.48px;
    white-space: nowrap;
    border-radius: 8px;
    border: 0.5px solid #7e84a3;
    margin-top: 8px;
    justify-content: center;
    padding: 8px 8px;
    font: 400 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .contactp-30 {
        white-space: initial;
    }
}

.contactp-31 {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.contactp-34 {
    align-self: stretch;
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    gap: 16px;
}

@media (max-width: 991px) {
    .contactp-34 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.contactp-35 {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.contactp-38 {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.contactp-41 {
    align-self: stretch;
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    gap: 16px;
}

@media (max-width: 991px) {
    .contactp-41 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.contactp-42 {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.contactp-43 {
    color: #2f2f2f;
    text-align: center;
    letter-spacing: -0.48px;
    white-space: nowrap;
    font: 600 16px/30px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .contactp-43 {
        white-space: initial;
    }
}

.contactp-45 {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.contactp-48 {
    align-self: stretch;
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    gap: 16px;
}

@media (max-width: 991px) {
    .contactp-48 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.contactp-49 {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}

.contactp-52 {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
}


.contactp-55 {
    background-color: #fff;
    align-self: stretch;
    display: flex;
    margin-top: 8px;
    flex-direction: column;
}

@media (max-width: 991px) {
    .contactp-55 {
        max-width: 100%;
    }
}

.contactp-57 {
    color: #7e84a3;
    letter-spacing: -0.42px;
    border-radius: 8px;
    border: 0.5px solid #7e84a3;
    margin-top: 8px;
    justify-content: center;
    padding: 21px 16px;
    font: 400 14px/24px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .contactp-57 {
        max-width: 100%;
    }
}

.contactp-58 {
    background-color: #fff;
    align-self: center;
    display: flex;
    margin-top: 16px;
    width: 340px;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
}

.contactp-59 {
    color: #f7fbfe;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #00e;
    padding: 13px 60px;
    font: 600 16px/22px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
    cursor: pointer;
}

@media (max-width: 991px) {
    .contactp-59 {
        white-space: initial;
        padding: 0 20px;
    }
}

body.active-modal {
    overflow-y: hidden;
}

select {
    border-right: 16px solid transparent
}

.button {
    cursor: pointer;
    justify-content: center;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    align-items: center;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 0px 1px rgba(255, 255, 255, 0.2) inset;
    background-color: #00e;
    align-self: center;
    margin-top: 6px;
    width: 360px;
    max-width: 100%;
    padding: 10px 0px;
    font: 700 17px/154% Inter, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #fff;
    border-radius: 12px;
    max-width: 750px;
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
}


.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

body {
    font-family: Arial, sans-serif;
}

select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    height: 45px;
    width: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
    background-position: 100%;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 0;
}



/* Modal Container Styles */
.modalContainer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: #FFF;
    padding: 20px;
    border: 3px solid #0074C7;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 70%;
}


@media (max-width: 991px) {
    .modal-content {
        top: 52%;
        max-width: 90%;
    }

}