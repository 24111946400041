.main-container {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    padding: 32px 0px 24px 0px;
}

@media (max-width: 991px) {
    .main-container {
        flex-wrap: wrap;
    }
}

.header {
    color: #00E;
    display: flex;
    font-style: bold;
    line-height: normal;
    letter-spacing: 0.24px;
    font: 700 24px Cairo, sans-serif;
    /* /* color: #00e;
    letter-spacing: 0.24px;
    margin-top: 17px; */
}

.form-container {
    border-radius: 8px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input-container {
    border-radius: 4px;
    border: 0.5px solid #00e;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 3px 0px;
}

@media (max-width: 991px) {
    .input-container {
        padding: 0 20px;
    }
}

.image-wrapper1 {
    cursor: pointer;
    padding: 2px 24px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    gap: 16px;
}

@media (max-width: 991px) {
    .image-wrapper1 {
        margin-left: 8px;
    }
}

.image-wrapper {
    padding: 2px 24px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    gap: 16px;
}

@media (max-width: 991px) {
    .image-wrapper {
        margin-left: 8px;
    }
}

.img-ContactUsMessages {
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
}

.all-inquires {
    font-size: 20px;
    font-style: bold;
}

.export-data {
    color: #00e;
    text-align: center;
    letter-spacing: -0.48px;
    align-self: stretch;
    flex-grow: 1;
    white-space: nowrap;
    font-style: semi-bold;
    font: 600 16px/30px Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .export-data {
        white-space: initial;
    }
}