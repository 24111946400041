.divud {
    border-radius: 16px;
    background-color: #fff;
    display: flex;
    max-width: 492px;
    flex-direction: column;
    padding: 24px 0;
}

.divud-2 {
    align-items: start;
    align-self: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 3px 0;
}

.modal-contentud {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #fff;
    border-radius: 12px;
    max-width: 490px;
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    .divud-2 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.divud-3 {
    display: flex;
    margin-top: 9px;
    flex-direction: column;
    padding: 0 20px;
}

.divud-4 {
    color: var(--grey-primary, #565656);
    font-feature-settings: "clig" off, "liga" off;
    font: 400 16px/175% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divud-5 {
    color: #2e353a;
    font-feature-settings: "clig" off, "liga" off;
    margin-top: 23px;
    white-space: nowrap;
    font: 700 24px/117% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divud-5 {
        white-space: initial;
    }
}

.imgud {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
    padding: 0px 8px;
    cursor: pointer;
}

.divud-6 {
    background-color: #e8e8e8;
    display: flex;
    min-height: 2px;
    margin-top: 26px;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    .divud-6 {
        max-width: 100%;
    }
}

.divud-7 {
    align-self: center;
    display: flex;
    margin-top: 14px;
    width: 100%;
    max-width: 443px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;
}

@media (max-width: 991px) {
    .divud-7 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.divud-8 {
    color: #00e;
    font-feature-settings: "clig" off, "liga" off;
    flex: 1;
    margin: auto 0;
    font: 700 16px/175% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.divud-9 {
    border-radius: 50px;
    border: 1px solid #f13416;
    background-color: #fff;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 9px;
}

.divud-10 {
    cursor: pointer;
    justify-content: space-between;
    align-items: start;
    display: flex;
    gap: 5px;
    padding: 0 1px;

}

.img-2 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 20px;
    overflow: hidden;
    max-width: 100%;
}

.divud-11 {
    color: #565656;
    align-self: stretch;
    flex-grow: 1;
    white-space: nowrap;
    font: 400 14px Poppins, sans-serif;
}

@media (max-width: 991px) {
    .divud-11 {
        white-space: initial;
    }
}

.divud-12 {
    background-color: #e8e8e8;
    display: flex;
    margin-top: 15px;
    width: 95%;
    flex-direction: column;
    padding: 13px 12px;
}

@media (max-width: 991px) {
    .divud-12 {
        max-width: 100%;
        padding: 0 20px;
    }
}

.divud-13 {
    color: #2f2f2f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divud-13 {
        max-width: 100%;
    }
}

.divud-15 {
    color: #2f2f2f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    margin-top: 16px;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divud-15 {
        max-width: 100%;
    }
}

.divud-16 {
    color: #7a797f;
    font-feature-settings: "clig" off, "liga" off;
    white-space: nowrap;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    margin-top: 6px;
    justify-content: center;
    align-items: start;
    padding: 13px 60px 13px 14px;
    font: 400 16px/175% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divud-16 {
        white-space: initial;
        max-width: 100%;
        padding-right: 20px;
    }
}

.divud-17 {
    color: #2f2f2f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    margin-top: 16px;
    font: 500 16px/28px Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divud-17 {
        max-width: 100%;
    }
}

.divud-18 {
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #fff;
    display: flex;
    margin-top: 6px;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 8px 14px;
}

@media (max-width: 991px) {
    .divud-18 {
        max-width: 100%;
        flex-wrap: wrap;
    }
}

.divud-19 {
    color: #7a797f;
    font-feature-settings: "clig" off, "liga" off;
    text-transform: capitalize;
    margin: auto 0;
    font: 400 16px/175% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

.img-3 {
    aspect-ratio: 0.83;
    object-fit: contain;
    object-position: center;
    width: 20px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.divud-20 {
    color: #f7fbfe;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    justify-content: center;
    border-radius: 8px;
    background-color: #00e;
    align-self: center;
    margin-top: 24px;
    width: 40%;
    align-content: center;
    padding: 13px 54px;
    font: 600 16px/135% Cairo, -apple-system, Roboto, Helvetica,
        sans-serif;
}

@media (max-width: 991px) {
    .divud-20 {
        white-space: initial;
        padding: 0 20px;
    }
}

.delete-user-confirmation {
    border-radius: 16px;
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    .delete-user-confirmation {
        max-width: 100%;
    }
}

.headerdelete {
    background-color: #e8e8e8;
    align-self: stretch;
    display: flex;
    min-height: 2px;
    margin-top: 26px;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    .header {
        max-width: 100%;
    }
}

.imageDelete {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 221px;
    overflow: hidden;
    align-self: center;
    margin-top: 22px;
    max-width: 100%;
}

.message {
    color: #2e353a;
    text-align: center;
    /* font-feature-settings: "clig" off, "liga" off; */
    max-width: 297px;
    align-self: center;
    font: 700 24px/28px Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

.bold-text {
    font: 400 16px/28px Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

.actions {
    align-self: center;
    display: flex;
    margin-top: 73px;
    width: 100%;
    max-width: 415px;
    justify-content: space-between;
    gap: 20px;
}

@media (max-width: 991px) {
    .actions {
        margin-top: 40px;
    }
}

.buttondelete {
    cursor: pointer;
    color: #2f2f2f;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    /* border: 1px solid #2f2f2f; */
    flex-grow: 1;
    padding: 15px 60px;
    font: 600 16px/135% Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .button {
        white-space: initial;
        padding: 0 20px;
    }
}

.red-button {
    color: #f7fbfe;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    border-radius: 8px;
    background-color: #f13416;
    flex-grow: 1;
    padding: 14px 51px;
    font: 600 16px/135% Cairo, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .red-button {
        white-space: initial;
        padding: 0 20px;
    }
}